<template>
  <div>
    <el-row :gutter="10"
            style="padding-bottom:10px">
      <el-col :lg="6"
              :md="12"
              :sm="12"
              :xl="4"
              :xs="12">
        <el-input clearable
                  style="width:100%"
                  v-model="favorite_tags"
                  @keyup.enter.native="search"
                  placeholder="请输入考试名称"></el-input>
      </el-col>
      <el-col :lg="12"
              :md="12"
              :sm="12"
              :xl="12">
        <el-button type="primary"
                   @click="search">查询</el-button>
        <el-button @click="add">新增</el-button>
      </el-col>
    </el-row>
    <Table ref="TablesRef"
           :tableData="tableData" />
    <pagination ref="pagination"
                :get-data="initData"
                :now-page.sync="page"
                :now-size.sync="size"
                :total="totalElements" />

  </div>
</template>

<script>
import { getExamList } from '@/api/exam.js'
export default {
  data () {
    return {
      page: 1,
      size: 10,
      totalElements: 0,
      suubjectList: [],
      subject_id: '',
      favorite_tags: '',
      tableData: [],
      pageName: 'edu_exam_admin',
      tableColumns: [
        { prop: 'test_paper_id', align: 'center', label: '考试ID', },
        { prop: 'test_name', align: 'center', label: '考试名称', },
        { prop: 'subject_name', align: 'center', label: '科目', },
        { prop: 'paper_name', align: 'center', label: '试卷名称', },
        { prop: 'status', align: 'center', label: '考试状态', isStatus: true },
        { prop: 'paper_test_type_name', align: 'center', label: '考试类型' },
        { prop: 'sheet_status', align: 'center', label: '答题卡状态', isStatus: true },
        {
          prop: 'd', align: 'center', label: '操作', width: '300', type: 'text', btns: [
            { name: '详情', class: 'text_btn_primary', event: 'toInfo' },
            { name: '答题卡列表', class: 'text_btn_primary', event: 'toReviewList' },
            { name: '学业质量分析', class: 'text_btn_primary', event: 'analyze' },
          ]
        },
      ]
    }
  },
  created () {
  },
  mounted () {
    this.setTablesColums()
  },
  methods: {
    getClass (prop, value) {
      return ''
    },
    getValue (prop, value, row) {

      if (prop == 'status') {
        if (value == 1) {
          let startTime = row.start_time_stamp
          let endTime = row.end_time_stamp
          let nowTimeStr = `${new Date().valueOf}`
          let nowTime = Number(nowTimeStr.slice(0, nowTimeStr.length - 3))

          let str = ''
          if (nowTime < startTime) {
            str = '准备完成'
          } else if (nowTime > startTime && startTime < endTime) {
            str = '考试中'
          } else {
            str = '考试完成'
          }
          return str

        } else {
          let statusObj = {
            0: "准备中",
            2: '答题卡识别中', 3: '批改中', 4: '批改完成'
          }
          return statusObj[value]
        }

      }

      if (prop == 'type') {
        // 1 全市统考 、2 本校统考、3  班级测试、4 小组考试、5 月考、6  周考、7 课堂测验
        let typeObj = {
          1: "全市统考",
          2: "本校统考",
          3: "班级测试",
          4: "小组考试",
          5: "月考",
          6: "周考",
          7: "课堂测验"
        }
        return typeObj[value]
      }
      if (prop == 'sheet_status' || prop == 'sheet_status2') {
        //"关联答题卡数据状态"  0 未关联  -1 取消申请 1 制作中  2 制作完成，未确定 3 已生成 4 申请中",
        switch (value) {
          case 0: return "未关联";
          case -1: return "取消申请";
          case 1: return "制作中";
          case 2: return "制作完成，未确定";
          case 3: return "已生成";
          case 4: return "申请中";
          default: break;
        }
      }
    },
    setTablesColums () {
      this.$refs.TablesRef.pageName = this.pageName
      this.$refs.TablesRef.setColumns(this.tableColumns)
    },
    async initData (page, limit) {
      let params = {
        page, limit
      }
      const { data } = await getExamList(params)
      console.log(data);
      this.tableData = data.list
      this.totalElements = data.count
    },
    toInfo (row) {
      this.$router.push('/exaMannage/add?id=' + row.test_paper_id)
    },
    toReviewList (row) {
      this.$router.push('/exaManage/sheetList?id=' + row.test_paper_id)
    },
    //答题卡审核
    reviewSheet (row) {
      this.$router.push('/exaManage/reviewSheet?id=' + row.test_paper_id)
    },
    // 刷新
    refresh () {
      this.$refs.pagination.shuaxin()
    },
    // 查询
    search () {
      this.$refs.pagination.toFirstPage()
    },
    //新增
    add () {

      this.$router.push("/exaMannage/add")
    },
    analyze (row) {
      this.$router.push("/exaMannage/analyze?test_paper_id=" + row.test_paper_id)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
